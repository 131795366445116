





























































import { Component, Vue } from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import CourseCard from "@/components/CourseCardEn.vue";
import firebase from "@/plugins/firebase";

@Component({
  metaInfo: {
    title: "Çevrimiçi Eğitimler"
  },
  components: {
    Breadcrumbs,
    CourseCard
  }
})
export default class CourseCatalog extends Vue {
  breadcrumbs = [
    {
      text: "Home",
      exact: true,
      to: { name: "home-en" }
    },
    {
      text: "Online Courses"
    }
  ];

  courses: {
    id: string;
    code: string;
    urlCode: string;
    urlCodeEn: string;
    name: string;
    desc: string;
    studentCount: number;
  }[] = [];

  async getCourses() {
    this.courses = [];

    await firebase
      .firestore()
      .collection("courses")
      .where("disabled", "==", false)
      .orderBy("name", "asc")
      .get()
      .then(query => {
        query.forEach(doc => {
          const newItem = {
            id: doc.id,
            code: doc.data().code,
            urlCode: doc.data().urlCode,
            urlCodeEn: doc.data().urlCodeEn,
            name: doc.data().nameEn,
            desc: doc.data().descEn.short,
            studentCount: doc.data().studentCount
          };

          this.courses.push(newItem);
        });
      });
  }

  async mounted() {
    await this.getCourses();
  }
}
